@use "sass:map";
@use "../../../scss/variables" as variables;

.PlanTripStepperContainer {
  background: var(--brand-Grey-200);
  display: block;

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    display: grid;
    grid-template-columns: 1fr 480px 1fr;
    align-items: center;
    padding: 30px 20px 20px 20px;
  }

  .PlanTripActionsMobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    padding: 15px;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      padding: 15px 20px;
    }

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      display: none;
    }
  }

  .homeLinkMobile {
    display: block;

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      display: none;
    }
  }

  .homeLinkDesktop {
    display: none;

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      display: block;
    }
  }

  .PlanTripStepper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 15px;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      padding: 25px 20px;
    }

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      padding: 0;
    }
  }

  .PlanTripStepCounter {
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0em;
    padding-left: 40px;
  }

  .PlanTripStepCounterDesktop {
    display: none;

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      display: block;
    }
  }

  .MuiStepper-root {
    width: 100%;
    margin: 0 auto;

    .MuiStep-root {
      @media (max-width: 991px) {
        &:first-child {
          .MuiStepLabel-root {
            align-items: flex-start;
          }

          .MuiStepLabel-label {
            text-align: left;
          }
        }

        &:nth-child(2) {
          .MuiStepConnector-root.MuiStepConnector-horizontal {
            left: calc(-100% + 15px);
          }
        }

        &:last-child {
          .MuiStepLabel-root {
            align-items: flex-end;
          }

          .MuiStepLabel-label {
            text-align: right;
          }

          .MuiStepConnector-root.MuiStepConnector-horizontal {
            right: 35px;
          }
        }
      }
    }
  }

  .MuiStepIcon-text {
    font-size: 0;
  }

  .MuiStepLabel-iconContainer {
    z-index: 1;
  }

  .MuiStepLabel-iconContainer.Mui-active {
    .MuiSvgIcon-root {
      color: #FFF;
      border: 5px solid var(--brand-Primary);
      border-radius: 50%;
      font-size: 40px;
    }
  }

  .MuiStepLabel-label.Mui-active {
    color: var(--brand-Grey-900);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    margin-top: 0;
  }

  .MuiStepLabel-iconContainer.Mui-disabled {
    margin-top: 4px;

    .MuiSvgIcon-root {
      font-size: 1.938rem;
      color: var(--brand-Grey-200);
      border: 3px solid var(--brand-Grey-500);
      border-radius: 50%;
    }
  }

  .MuiStepLabel-label.Mui-disabled {
    color: var(--brand-Grey-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin-top: 5px;
  }

  .MuiStepLabel-iconContainer.Mui-completed {
    margin-top: 4px;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: var(--brand-Primary);

    .MuiSvgIcon-root {
      display: none;
    }
  }

  .MuiStep-root:last-child {
    .MuiStepLabel-iconContainer.Mui-completed {
      .MuiSvgIcon-root {
        width: 31px;
        height: 31px;
        background: #ffffff;
        border-radius: inherit;
        display: block;
      }
    }
  }


  .MuiStepLabel-label.Mui-completed {
    color: var(--brand-Grey-900);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    margin-top: 5px;
  }

  .MuiStepConnector-root.MuiStepConnector-horizontal {
    z-index: 0;
    top: 19px;
    left: calc(-50% + 15px);
    right: calc(50% + 15px);

    .MuiStepConnector-line {
      border-color: var(--brand-Grey-500);
      border-top-width: 3px;
    }

  }

  .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed,
  .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active {

    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-color: var(--brand-Primary);
      border-top-width: 3px;
    }
  }
}