.labelIconWithLetterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  margin-left: 2px;
}

.labelIcon {
  color: inherit;
  z-index: 0;
  transform: scale(1.6);
}

.positionLetter{
  position: absolute;
  color: white;
  margin-left: -2px;
  font-weight: 700;
}