.TripDetailsAssistance {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 15px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.TripDetailsAssistanceTitle {
  color: var(--brand-Base-Black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripDetailsAssistanceValue {
  font-size: 1rem;
}

.TripDetailsAssistanceList {
  margin: 0;
  padding: 0;
  list-style: none;
}