.TripDuration {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.TripDurationTitle {
  color: var(--brand-Base-Black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripDurationDuration {
  min-width: 144px;
}

.TripDurationValue {
  font-size: 0.875rem;
  line-height: 1.5rem;
}