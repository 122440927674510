@use "sass:map";
@use "../../scss/variables" as variables;

.backToMainMenu {
  display: flex;
  align-items: center;
  color: var(--brand-Primary-700);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--brand-Primary-900);
  }

  svg {
    font-size: 1.375rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: var(--brand-Grey-900);
  }
}

.backToMainMenuDesktopText {
  display: none;

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    display: block;
  }
}

.backToMainMenuMobileText {
  display: block;

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    display: none;
  }
}