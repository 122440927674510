.dividerVertical {
    border: 1px solid #ABB7BA;
    margin-right: 5px !important;
    margin-left: 5px !important;
    opacity: unset !important;
}

.mergeIconAndRoundTile{
    display: flex;
    align-items: center;
}
.flex-nowrap{
    flex-wrap: nowrap !important;
}