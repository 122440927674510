.TripDetailsRoute {
  margin-bottom: 12px;
}

.TripDetailsRouteTitle {
  color: var(--brand-Base-Black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 6px;
}

.TripDetailsRouteItem {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  .labelIconWithLetterContainer {
    margin-left: -10px;
    padding-right: 0;

    .labelIcon.MuiSvgIcon-root {
      transform: none;
      font-size: 30px;
    }

    .positionLetter {
      font-size: 0.875rem;
    }
  }

  .TripDetailsRouteItemIcon {
    flex-grow: 0;
    width: 35px;
  }

  .TripDetailsRouteItemDesc {
    flex-grow: 0;
    width: calc(100% - 35px);

    span {
      display: block;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      flex-grow: 0;
      width: 100%;
      margin-top: 4px;
    }
  }
}

.TripDetailsRouteTimes {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 15px;
  margin-top: 12px;
}

.TripDetailsRouteTimesTitle {
  color: var(--brand-Base-Black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripDetailsRouteTimesValue {
  font-size: 1rem;
}

.TripDetailsUnconfirmedRouteTimesValue {
  font-size: 1rem;
  color: red;
}