.PlanTripContainer .Label {
  display: flex;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: 0em;
  margin-bottom: 4px;
}

.MuiAutocomplete-option {
  border-bottom: 1px solid #E3E7E8;
  padding: 15px !important;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
}

.MuiAutocomplete-root {
  .MuiInputBase-root {
    >svg {
      margin-right: 4px;
      font-size: 1.5rem;
    }
  }
}

.accessibilityHideFromView {
  position: absolute;
  left: -9999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
}