@use "sass:map";
@use "./variables" as variables;

h1 {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.625rem;
  letter-spacing: -0.34px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 2.125rem;
  }
}