.TripTimes {
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 15px;
}

.TripTimesTitle {
  color: var(--brand-Base-Black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripTimesValue {
  font-size: 0.875rem;
}