.PlanTripStep3 .label {
    display: flex;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 9px;
}

.PlanTripStep3 .text {
    display: flex;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.yourReservation {
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 20px;
}

.PlanTripContainer .PlanTripStep3 .sub-title-step {
    font-weight: 500;
}

.PlanTripContainer .PlanTripStep3 .subTitleStepContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.2rem 0;
    padding: 8px 0;
    flex-wrap: wrap;
}

.PlanTripContainer .PlanTripStep3 .noMarginTop {
    margin-top: 0px;
}

.PlanTripStep3 .MuiStack-root .MuiBox-root {
    padding: 8px 0;
}

.capitalize {
    text-transform: capitalize;
}