@forward 'bootstrap/scss/bootstrap';
@forward 'variables';
@forward 'typography';
@forward 'layout';
@forward 'material-ui-override';
@forward 'trips-list';
@forward 'alerts';

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: #FAFAFA;
}

::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: red;
  font-weight: 400;
}


.MuiTab-root.Mui-focusVisible {
  outline-offset: -2px;
}

.Mui-focusVisible {
  outline: auto 2px #000000 !important;
  outline-offset: 4px;
}

.MuiInputBase-input.MuiOutlinedInput-input::placeholder {
  opacity: 1;
  color: #757575
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}