@use "sass:map";
@use "../../scss/variables" as variables;

.radio-tuile-container {
    justify-content: space-between;

    label.radio-tuile {
        width: 160px;
        height: 48px;
        border-radius: 8px;
        box-shadow: 0px 0px 4px 0px #00000040;
        margin: 0;

        @media (min-width: map.get(variables.$breakpoints, 'sm')) {
            width: 200px;
        }
    }

    label.radio-tuile input {
        margin-left: 5px;
    }

    label.radio-tuile span {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    label.radio-tuile:has(span.Mui-checked) {
        background: #ffffff;
        border: solid 2px #008199;
    }


}