.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.noAccessContainer {
  padding: 16px 16px 24px;
  width: 430px;
}
