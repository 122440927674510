.TripAddresses {
  margin-bottom: 12px;
}

.TripAddressesTitle {
  color: var(--brand-Base-Black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 6px;
}

.TripAddressesItem {
  position: relative;
  width: 100%;

  span {
    display: block;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    flex-grow: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}