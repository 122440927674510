.TripRequestType {
  border-radius: 12px;
  height: 28px;
  background: var(--brand-color-Regular);
  display: inline-flex;
  align-items: center;
  line-height: 1.75rem;
  padding: 0 20px 0 12px;

  span {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  &.TripRequestTypeOccasional {
    background: var(--brand-color-Occasional);
  }

  &.TripRequestTypeUnconfirmed {
    background: var(--brand-color-Unconfirmed);
    color: white;
  }
}

.TripRequestTypeIcon.MuiSvgIcon-root {
  margin-right: 6px;
  font-size: 1.25rem;
  margin-bottom: -1px;
}