@use "sass:map";
@use "../../scss/variables" as variables;

.Header {
  &__top {
    background: var(--brand-Base-Black);
    color: #FFF;
    min-height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0 15px;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      grid-template-columns: 1fr 440px 1fr;
      padding: 0 20px;
    }
  }

  &__title {
    margin: 0;
    color: var(--brand-Grey-100);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.2px;
    width: 100%;
    max-width: 440px;
  }

  &__logout {
    color: #FFF;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    text-decoration: none;
    text-align: right;
    display: inline-block;
    background: transparent;
    border: none;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      font-size: 1rem;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__brand {
    a {
      color: #FFF;
      font-size: 1.25rem;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        font-size: 1.813rem;
      }
    }
  }
}