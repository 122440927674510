.PlanTripSuccess {
  margin-bottom: 36px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 15px 40px !important;

  .MuiAlert-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .MuiAlert-icon {
    margin-right: 0;

    .MuiSvgIcon-root {
      font-size: 60px !important;
      color: #2D9563;
    }
  }

  .MuiAlertTitle-root {
    color: #053611 !important;
    margin-bottom: 8px;
  }

  .MuiButtonBase-root {
    margin-top: 16px;
    width: 100%;
    max-width: 255px;
  }

  .MuiButton-containedSuccess {
    background-color: #2D9563;
  }
}