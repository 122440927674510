@use "sass:map";
@use "../../scss/variables" as variables;


.Home {

  &__intro {
    margin-bottom: 28px;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      margin-bottom: 40px;
    }

    &-title {
      color: #000;
      font-family: Montserrat, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.24px;
      margin-bottom: 0;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        font-size: 2.125rem;
        line-height: 2.625rem;
        letter-spacing: -0.34px;
        font-weight: 800;
        margin-bottom: 8px;
      }
    }

    &-title-mobile {
      margin-bottom: 30px;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        display: none;
      }
    }

    p {
      color: #000;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: -0.18px;
    }
  }

  &__cards {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 46px;
    row-gap: 40px;

    @media (min-width: map.get(variables.$breakpoints, 'sm')) {
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      row-gap: 30px;
    }

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 46px;
      row-gap: 46px;
    }

    &-item {
      border-radius: 16px;
      background: #FFF;
      color: var(--brand-Base-Black);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
      padding: 50px 40px 40px;
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: -0.18px;
      text-decoration: none;
      display: flex;
      flex-direction: column;

      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0px 0px 0px 2px var(--brand-Primary);
        background: #F5F5F5;
        outline: none;
      }

      &:focus,
      &:focus-visible {
        background: #FFF;
      }
    }

    &-icon {
      font-size: 6.875rem;
      margin-bottom: 20px;
    }

    &-arrow {
      margin-top: 20px;

      svg {
        font-size: 2.125rem;
      }
    }
  }
}

.accordions-container {
  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    border-radius: 16px;
  }

  border-radius: 0px;
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  background: #FFF;
  color: var(--brand-Base-Black);
  padding: 20px 40px 40px;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.accordions-container .accordions-item {
  background: #FAFAFA;
  border-radius: 12px;
  font-weight: 700;
  border: solid 1px #E3E7E8;

  .MuiAccordionSummary-root {
    min-height: 80px;
  }

  .MuiSvgIcon-root {
    color: #222121;
  }
}

.accordionIcon {
  display: flex;
  align-items: center;
}

.accordionIcon p {
  font-weight: 700;
  align-items: center;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 1.1rem;
  }
}

.justify {
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
}

.accordionIcon svg {

  display: none;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    display: inline-flex;
    margin-right: 15px;
    width: 42px;
    height: 40px;
  }
}

.marginTop {
  margin-top: 20px;
  max-width: 793px;
}

.accordion-info {
  font-weight: 700;
}