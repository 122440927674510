@use "sass:map";
@use "../../scss/variables" as variables;

.PlanTripContainer {
  width: 100%;
  max-width: 440px;
  margin: 40px auto 0 auto;
}

.PlanifierUnTrajet-link {
  color: var(--brand-Primary-700);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;

  &:hover,
  &:focus {
    color: var(--brand-Primary-900);
  }
}

.PlanTripTitle {
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 2.625rem;
  margin-bottom: 10px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 2.125rem;
  }
}

.PlanTripText {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 40px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 1.25rem;
  }
}

.LabelAddress {
  font-family: Montserrat, sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  line-height: 1.75rem !important;
  letter-spacing: 0em !important;
}

.btnStep1 {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-top: 40px;
  background-color: #008199;
  border-color: #008199;
}

.PlanTripContainer .title-step {
  font-weight: 800;
  font-size: 1.875rem;
  line-height: 2.625rem;
  margin-bottom: 25px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 34px;
  }
}

.PlanTripContainer .sub-title-step {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 5px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    font-size: 1.25rem;
  }
}

.PlanTripContainer .MuiFormLabel-root {
  color: #000;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
}

.PlanTripContainer .MuiFormControl-root.container {
  padding-left: 0;
  padding-right: 0;
}

.PlanTripContainer .field.date-picker,
.PlanTripContainer .field.time-picker {
  .MuiInputAdornment-positionStart {
    margin-right: 4px;
  }

  .MuiOutlinedInput-input {
    font-weight: 700;
  }

  &::placeholder {
    font-weight: 400;
  }

  button {
    font-size: 1.5rem;
    margin-left: -8px;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.PlanTripContainer .field.date-picker {
  button {
    svg {
      margin-bottom: -5px;
    }
  }
}

.PlanTripContainer .radio-group-container {
  margin-bottom: 20px;
}

.MuiFormControl-root {
  width: 100%;
}