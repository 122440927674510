@use "sass:map";
@use "../../../scss/variables" as variables;

.planTripBanner {



  .banner {
    background-color: #FCEFC5;
    justify-content: center;
    min-height: 64px;
    border-radius: 0;
    position: relative;
    padding: 10px 40px 10px 15px;
    margin-bottom: 1.25rem;

    .banner-title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      padding: 6px 40px;
    }

    p {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .MuiAlert-action {
      position: absolute;
      top: 10px;
      right: 10px;
      margin: 0;
      padding: 0;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        top: 50%;
        margin: -14px 0 0 0;
      }
    }
  }
}