@use "sass:map";
@use "../../scss/variables" as variables;

.Footer {
  padding: 60px 0 30px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    padding: 60px 0 30px;
  }

  &__text {
    color: #000;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    width: 100%;
    margin: 0 0 20px;
  }

  &__nav {
    width: 100%;
    margin: 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      li {
        position: relative;

        &:after {
          content: '';
          width: 1px;
          height: 24px;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -12px;
          background-color: var(--brand-Primary);
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        a {
          color: var(--brand-Primary);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 2rem;
          display: inline-block;
          padding: 0 10px;
          text-decoration: none;

          svg {
            font-size: 1.125rem;
          }

          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}