@use "sass:map";
@use "./variables" as variables;

.TripsList {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 46px;
  row-gap: 40px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    column-gap: 30px;
    row-gap: 30px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    grid-template-columns: calc(33.333% - 31px) calc(33.333% - 31px) calc(33.333% - 31px);
    column-gap: 46px;
    row-gap: 46px;
  }
}

.TripsListItem {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.TripsListItemHeader {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  column-gap: 10px;
  margin-bottom: 12px;

  h3 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.TripsListItemHeaderRight {
  text-align: right;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.TripsListItemBody {
  flex-grow: 1;
}

.TripsListItemActions {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .MuiButton-outlined {
    width: 100%;
    max-width: 140px;
  }
}

.TripsListItemActionsNextTrips {
  display: grid; 
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  row-gap: 10px;

  button {
    white-space: nowrap;
  }

  .MuiButton-outlined {
    min-width: 110px;
  }
}