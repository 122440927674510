@use "sass:map";
@use "../../../scss/variables" as variables;


.btnContainer .btnNextStep {
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  background-color: #008199;
  border-color: #008199;
  --bs-btn-hover-bg: #005766;
  --bs-btn-hover-border-color: #005766;
  --bs-btn-active-bg: #008199;
  --bs-btn-active-border-color: #008199;
}

.btnContainer {
  display: flex;
  font-family: Montserrat, sans-serif;
  margin-top: 40px;
}

.btnContainer .btnBack {
  padding: 8px;
  min-width: 52px;
  margin-right: 30px;
  border: solid 2px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    margin-right: 15px;
  }

}

.btnContainer .btnBack span {
  margin: 0;
}

.btnCancelContainer {
  margin-top: 10px;
}

.btnCancel {
  width: 100%;
}

.btnSeeMyTrips {
  width: 100%;
}

.btnReturnMainMenuContainer {
  margin-top: 10px;
}

.btnReturnMainMenu {
  width: 100%;
}