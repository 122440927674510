@use "sass:map";
@use "../../../scss/variables" as variables;

.banners {
  .banner-resume {
    justify-content: flex-start;
    background-color: #B3D9E0;
    color: #000;
    border-radius: 0;
    padding: 10px 16px;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      padding: 6px 16px;
      justify-content: center;
    }

    .positionLetter {
      color: #B3D9E0;
      font-weight: 700;
    }
  }

  .banner-resume-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    max-width: 100%;

    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      flex-direction: row;
      font-size: 1rem;
    }

    .origin {
      margin-bottom: 15px;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        margin-bottom: 0;
      }
    }

    .origin,
    .destination {
      display: flex;
      width: 100%;
      align-items: flex-start;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        width: calc(50% - 50px);
        max-width: 500px;
        align-items: center;
      }

      p {
        min-width: fit-content;
      }
    }

    .destination-arrow {
      margin-right: 10px;
      margin-left: 20px;
      display: none;

      @media (min-width: map.get(variables.$breakpoints, 'md')) {
        display: block;
      }
    }
  }

  .MuiAlert-message {
    display: inline-flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .bus-icon {
    display: none;
    margin-right: 15px;
    border-right: 1px solid #000;
    padding-right: 10px;

    @media (min-width: map.get(variables.$breakpoints, 'lg')) {
      display: block;
    }

    svg {
      font-size: 1.625rem;
    }
  }

  .banner-address {
    @media (min-width: map.get(variables.$breakpoints, 'md')) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex-grow: 1;
    }
  }
}