.TripDetailsAddresses {
  margin-bottom: 12px;
}

.TripDetailsAddressesTitle {
  color: var(--brand-Base-Black);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 20px;
}

.TripDetailsAddressesList {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 18px 0;
    border-bottom: var(--bs-border-width) dotted;
    border-color: rgba(0, 0, 0, 0.25);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.TripDetailsAddressesItem {
  position: relative;
  width: 100%;

  span {
    display: block;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    flex-grow: 0;
    width: 100%;
  }
}

.TripDetailsAddressesTimes {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 15px;
  margin-bottom: 15px;
}

.TripDetailsAddressesTimesTitle {
  color: var(--brand-Base-Black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripDetailsAddressesTimesValue {
  font-size: 1rem;
}

.TripDetailsReturnJourney {
  margin-top: 20px;
}