@use "sass:map";
@use "./variables" as variables;

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }
}

.container-fluid {
  max-width: 1155px;
  padding: 0 15px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    padding: 0 20px;
  }
}