.TripDetailsClientInfo {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 15px;
  row-gap: 25px;
  margin-bottom: 20px;
}

.TripDetailsClientInfoTitle {
  color: var(--brand-Base-Black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.TripDetailsClientInfoValue {
  font-size: 1rem;
  line-height: 1.5rem;
}