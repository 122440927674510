@use "sass:map";
@use "../../../scss/variables" as variables;

.RegularTrips {
  .InformationAlert {
    margin-top: 60px;
  }
}

.RegularTripsIntro {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 40px;
}
