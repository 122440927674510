.roundTrip {
    border:2px solid #FFDA87;    
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
}

.displayBlock>span {
    display: inline-flex;
    margin-right: 10px;
}