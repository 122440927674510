:root, :host {
  --brand-Base-Black: #222121;
  --brand-Grey-100: #EFEFEF;
  --brand-Grey-200: #E3E7E8;
  --brand-Grey-500: #8F9FA3;
  --brand-Grey-700: #5C6C70;
  --brand-Grey-900: #2B373B;
  --brand-Primary: #008199;
  --brand-Primary-700: #006E86;
  --brand-Primary-900: ##00516B;
  --brand-color-Occasional: #FFDB87;
  --brand-color-Unconfirmed: #A42B29;
  --brand-color-Regular: #B5DDE1;
}

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);