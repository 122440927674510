.MuiAlertTitle-root {
  font-family: Montserrat, sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.18px;
  margin-top: 3px !important;
}

.MuiAlert-root .MuiAlert-icon .MuiSvgIcon-root {
  font-size: 1.875rem;
}

.MuiAlert-message {
  color: #2D3648;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.MuiAlert-standardError {
  border: 1px solid #A32929;
  background-color: #FFF3F3;
  border-radius: 0 !important;

  .MuiAlertTitle-root {
    color: #A32929;
  }
}

.MuiAlert-standardSuccess {
  border: 1px solid #2D9563;
  background-color: #F8FEF5;
  border-radius: 0 !important;

  .MuiAlertTitle-root,
  .MuiAlert-icon {
    color: #2D9563;
  }
}

.MuiAlert-standardInfo {
  background-color: #E7F5FF !important;
  border-radius: 0 !important;

  .MuiAlertTitle-root,
  .MuiAlert-icon {
    color: #0071BA;
  }
}