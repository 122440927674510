
@use "sass:map";
@use "../../scss/variables" as variables;

.PageWrapperBackToMainMenu {
  padding: 10px 15px 20px 15px;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    padding: 38px 20px 30px 20px ;
  }
}

.PageWrapper {
  margin-bottom: 20px;
}
