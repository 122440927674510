.PlanTripTitle {
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 2.625rem;
  margin-bottom: 10px;
}

.PlanTripText {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 40px;
}

.btnStep1 {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-top: 40px;
  background-color: #008199;
  border-color: #008199;
  --bs-btn-hover-bg: #005766;
  --bs-btn-hover-border-color: #005766;
  --bs-btn-active-bg: #008199;
  --bs-btn-active-border-color: #008199;
}

.iconButton {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0;
    background: transparent;
    color: #EFEFEF;

  }
}

.swapButton button svg {
  font-size: 40px;
}

.attendantLabel {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.attendantText {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em; 
  margin-bottom: 10px;
}

.assistiveDeviceLabel,
.numberOfChildAllowedLabel {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-top: 25px;
}

.assistiveDeviceText span {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-transform: lowercase;
}

.assistiveDeviceText span::first-letter {
  text-transform: uppercase;
}

@keyframes flickerAnimation {
  0% {
    color: rgba(0, 0, 0, 0);
  }

  50% {
    color: rgba(0, 0, 0, 0.4);
  }

  100% {
    color: rgba(0, 0, 0, 0);
  }
}

.flicker {
  animation: flickerAnimation 0.2s;
}

.plan-trip-form {
  .ServerError {
    margin-bottom: 40px;
  }
}

.margin-top{
  margin-top: 10px;
}