.CancelTripDialog {
  .MuiPaper-root {
    max-width: 560px !important;
  }
}

.displayBlock{
  display: block !important;
}

.displayEnd{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}