@use "sass:map";
@use "../../scss/variables" as variables;

.LanguageSwitcher {
  padding: 5px 4px 5px 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;

  @media (min-width: map.get(variables.$breakpoints, 'md')) {
    padding: 10px 4px 10px 20px;
    margin-bottom: 30px;
  }
}