@use "sass:map";
@use "../../scss/variables" as variables;

.ProfilIntro {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 30px;
}

.ProfilInformations {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  row-gap: 25px;

  @media (min-width: map.get(variables.$breakpoints, 'sm')) {
    grid-template-columns: 3fr 2fr;
  }
}

.ProfilSubtitle {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.ProfilValue {
  font-size: 1rem;
  line-height: 1.5rem;
}

.Profile-assistiveDeviceItem {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}