.TripRoute {
  margin-bottom: 12px;
}

.TripRouteTitle {
  color: var(--brand-Base-Black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 6px;
}

.TripRouteItem {
  display: flex;
  align-items: center;
  position: relative;

  .labelIconWithLetterContainer {
    margin-left: -10px;
    padding-right: 0;

    .labelIcon.MuiSvgIcon-root {
      transform: none;
      font-size: 1.875rem;
    }

    .positionLetter {
      font-size: 0.875rem;
    }
  }

  .TripRouteItemIcon {
    flex-grow: 0;
    width: 35px;
  }

  .TripRouteItemDesc {
    flex-grow: 0;
    width: calc(100% - 35px);

    span {
      display: block;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      flex-grow: 0;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}