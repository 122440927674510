@use "sass:map";
@use "../../../scss/variables" as variables;

.NextTrips {
  .NextTripsIntro {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin-bottom: 40px;
  }

  .ServerError, .ServerSuccess {
    margin-bottom: 20px;
  }

  .InformationAlert {
    margin-top: 30px;
  }
}