.PlanTripStep2 .field {
    width: 100%;
}

.PlanTripStep2 .bussiness-hours-container {
    background-color: #EFEFEF;
    padding: 20px;
    margin-bottom: 35px;
}

.PlanTripStep2 .bussiness-hours-container .title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.PlanTripStep2 .bussiness-hours-container .text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.PlanTripStep2 .radio-group-container {
    margin-bottom: 30px;
}

.PlanTripStep2 .label-radio span {
    color: #000;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
}

.PlanTripStep2 .label-container {
    display: inline-flex;
}

.PlanTripStep2 .label-container button {
    margin: 0 0 10px 10px;
    padding: 0;
}

.PlanTripStep2 .label-field-with-format span {
    font-weight: 400;
}

.PlanTripStep2 .MuiFormControl-root {
    margin-bottom: 15px;
}

.PlanTripStep2 .time-picker {
    margin-bottom: 0;
}

.planTripBanner .banner .banner-title,
.banners.step2 .banner-text {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

// Datepicker customisation
.MuiPickersLayout-root .MuiPickersCalendarHeader-labelContainer {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.MuiPickersLayout-root .MuiPickersCalendarHeader-labelContainer .MuiPickersCalendarHeader-label {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.MuiPickersLayout-root .MuiPickersCalendarHeader-switchViewButton {
    display: none;
}

.MuiPickersLayout-root .MuiPickersDay-root {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.MuiPickersLayout-root .MuiPickersDay-root.Mui-disabled {
    font-weight: 400;
    text-decoration: line-through;
}

// TimePicker Customisation
.time-picker-dialog {
    .MuiList-root {
        width: calc(calc(440px /3)/2);
    }

    li.MuiMenuItem-root {
        width: 65px;
    }

    .MuiButtonBase-root.MuiMenuItem-root.MuiMultiSectionDigitalClockSection-item.Mui-disabled {
        display: none;
    }

    .MuiMultiSectionDigitalClock-root {
        width: 100%;
    }
}


.time-picker-dialog .MuiPickersLayout-actionBar {
    display: flex;
    justify-content: center;
}

.MuiMultiSectionDigitalClockSection-item {
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.MuiMultiSectionDigitalClockSection-item.Mui-disabled {
    font-weight: 400 !important;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMultiSectionDigitalClockSection-item.Mui-disabled {
    opacity: 1;
    color: #757575;
}

.PlanTripStep2 .departResumeContainer {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #C7CFD1;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.PlanTripStep2 .departResumeContainer .title {

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 15px;
}

.PlanTripStep2 .departResumeContainer .left {
    width: 75%;
}

.PlanTripStep2 .departResumeContainer .right {
    width: 25%;
    position: relative;
}

.PlanTripStep2 .departResumeContainer .right button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 6px 16px 6px 16px;

}

.PlanTripStep2 div {
    padding: 0;
}

.PlanTripStep2 .item {
    display: flex;
}

.PlanTripStep2 .departResumeContainer .label {
    display: flex;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 9px;
}

.PlanTripStep2 .departResumeContainer .text {
    display: flex;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 0px;
}

.PlanTripStep2 .departResumeContainer .text.capitalize {
    text-transform: capitalize;
    margin-left: 5px;
}

.PlanTripStep2 .departResumeContainer .marginLeft {
    margin-left: 5px;
}