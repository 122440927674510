.error-container {
  display: flex;
  align-items: center;
}

.error-container .errorText {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.error-container .errorLabel {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
}

.error-container .errorIcon {
  margin-right: 5px;
}

.error-container .errorColor {
  color: #A32929;
  border-color: #A32929;
}